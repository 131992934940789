(function () {
  const isEn = window.location.pathname === "/en.html";
  function setUI(current) {
    const currentVal = +current;
    const maxVal = isEn ? 50000 : 2000000;
    const maxSeats = 400;
    const oneCar = maxVal / 5;
    const numSeats = Math.floor(currentVal / (maxVal / maxSeats));
    const numFullCars = Math.floor(currentVal / oneCar);
    const percentOfLast = (currentVal - numFullCars * oneCar) / oneCar;
    const cars = document.querySelectorAll("#train > div");
    cars.forEach((car, index) => {
      if (index < numFullCars + 1) {
        var child = document.createElement("div");
        child.className = "car-full";
        if (index === numFullCars) {
          child.classList.add("last");
          child.style = "right: " + (100 - percentOfLast * 100) + "%;";
        }
        car.appendChild(child);
      }
    });
    document.querySelector("#currentAmount").innerHTML = currentVal
      .toLocaleString("en")
      .replaceAll(",", " ");
    document.querySelector("#numSeats").innerHTML = String(numSeats);
  }

  const API_KEY = "AIzaSyD3ruH2zh-7aaUDtjo-DfyXezDEKj1dsZE";

  const spreadsheetId = "1M0hQ-ToHqGX2eZ5gQuBmxNFGv0JtZjlOxQqWGNTywdE";
  const range = "Sheet1!A1:B1";

  function fetchCurrentAmount() {
    fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${API_KEY}`
    )
      .then((resp) => resp.json())
      .then((json) => {
        const currentVal = json.values[0][isEn ? 1 : 0];
        setUI(currentVal);
      });
  }
  // setUI(1000000);
  fetchCurrentAmount();
})();
